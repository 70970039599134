import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts';
import * as showdown from 'showdown';
import {
  WhiteBox,
  WhiteBoxGreyText,
  PureWhiteBox,
  PureWhiteBoxNoBottom,
  DarkGreyBox,
  DarkGreyNoYBox,
  Inner,
  OrangeBox,
  prepID,
  ImgResponsive,
  CenterBox,
} from '../components/shared';
import { Box } from '@material-ui/core';
import SEO from '../components/seo';
import styled from '@emotion/styled';
import { Globals, Breakpoints } from '../styles/global';
const converter = new showdown.Converter();

const UL = styled.ul`
  list-style-type: none;
  padding: 0 2rem 0 0;
  margin: 2.5rem 0 0 0;
  a {
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: ${Globals.orange};
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ViewLink = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    font-weight: 500;
  }
`;

const GridBox = styled.div`
  display: flex;
  ${Breakpoints.sm`
    > div:first-of-type {
      flex-shrink: 0;
      width: 12rem;
      margin-right: 2rem;
    }
  `}
`;
export const BasicPageTemplate = ({
  frontmatter,
}: {
  frontmatter: {
    title: string;
    tagline: string;
    content: string;
    title2: string;
    content2: string;
    title3: string;
    content3: string;
    title4: string;
    content4: string;
    title5: string;
    link5: string;
    title6: string;
    link6: string;
    title7: string;
    content7: string;
  };
}) => {
  const {
    title,
    tagline,
    content,
    title2,
    content2,
    title3,
    content3,
    title4,
    content4,
    title5,
    link5,
    title6,
    link6,
    title7,
    content7,
  } = frontmatter;
  return (
    <>
      <SEO title={title} descritipn={tagline} />
      <CenterBox style={{ backgroundColor: Globals.grayscale_b }}>
        <DarkGreyBox
          style={{
            backgroundImage: 'url(/about/shake.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            maxWidth: '150rem',
          }}
        >
          <Inner>
            <Box
              className="my-u6"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <q className="font-u1 text-center">{tagline}</q>
            </Box>
          </Inner>
        </DarkGreyBox>
      </CenterBox>
      <PureWhiteBox id={prepID(title)}>
        <Inner>
          <GridBox>
            <Box display={['none', 'flex']}>
              <UL className="font-d1">
                <li>
                  <a href={`#${prepID(title)}`}>{title}</a>
                </li>
                <li>
                  <a href={`#${prepID(title2)}`}>{title2}</a>
                </li>
                <li>
                  <a href={`#${prepID(title3)}`}>{title3}</a>
                </li>
                <li>
                  <a href={`#${prepID(title4)}`}>{title4}</a>
                </li>
                <li>
                  <Link to={link5}>{title5}</Link>
                </li>
                <li>
                  <Link to={link6}>{title6}</Link>
                </li>
                <li>
                  <a href={`#${prepID(title7)}`}>{title7}</a>
                </li>
              </UL>
            </Box>
            <Box>
              <h1 className="font-u2">{title}</h1>
              <div
                className="markdown-holder mb-u6"
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(content),
                }}
              />
            </Box>
          </GridBox>
        </Inner>
      </PureWhiteBox>
      <PureWhiteBoxNoBottom id={prepID(title2)}>
        <Inner>
          <Box display={['block', 'flex']} justifyContent="space-between">
            <Box flexGrow={0} width={['100%', '50%']}>
              <Box marginRight={['0', '2rem']}>
                <h2 className="font-u1 mb-b">{title2}</h2>
                <div
                  className="markdown-holder mb-u6 font-u1"
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(content2),
                  }}
                />
                <h2 className="font-u1 mb-b" id={prepID(title3)}>
                  {title3}
                </h2>
                <div
                  className="markdown-holder mb-u6 font-u1"
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(content3),
                  }}
                />
              </Box>
            </Box>
            <Box
              width={['100%', '50%']}
              display={['block', 'flex']}
              flexShrink={0}
            >
              <ImgResponsive
                src="/about/family.jpeg"
                alt="family poses for a photo"
              />
            </Box>
          </Box>
        </Inner>
      </PureWhiteBoxNoBottom>
      <DarkGreyNoYBox id={prepID(title4)}>
        <Inner>
          <Box display={['block', 'flex']} justifyContent="space-between">
            <Box
              width={['100%', '50%']}
              className="py-u3"
              flexGrow="0"
              flexShrink="0"
            >
              <Box marginRight={[0, '2rem']}>
                <h3 className="font-u3 mb-u4">{title4}</h3>
                <div
                  className="markdown-holder mb-u2 font-b"
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(content4),
                  }}
                />
              </Box>
            </Box>
            <Box width={['100%', '50%']} flexGrow="0" flexShrink="0">
              <ImgResponsive src="/about/pen.png" alt="" />
            </Box>
          </Box>
        </Inner>
      </DarkGreyNoYBox>
      <OrangeBox id={prepID(title5)}>
        <Inner justifyContent="space-between" display="flex" flexWrap="no-wrap">
          <h3 className="font-u3">{title5}</h3>
          <ViewLink>
            <Link to={link5} className="font-b white-link">
              View »
            </Link>
          </ViewLink>
        </Inner>
      </OrangeBox>

      <WhiteBoxGreyText id={prepID(title6)}>
        <Inner justifyContent="space-between" display="flex" flexWrap="no-wrap">
          <Box order={['1', '2']}>
            <h3 className="font-u3">{title6}</h3>
          </Box>
          <Box order={['2', '1']}>
            <ViewLink>
              <Link to={link6} className="font-b grey-link">
                View »
              </Link>
            </ViewLink>
          </Box>
        </Inner>
      </WhiteBoxGreyText>
      <DarkGreyNoYBox id={prepID(title7)}>
        <Inner display={['block', 'flex']} justifyContent="space-between">
          <Box width={['100%', '50%']}>
            <ImgResponsive src="/about/hands2.jpg" alt="" />
          </Box>

          <Box
            width={['100%', '50%']}
            marginLeft={['0', '2rem']}
            className="py-u2"
          >
            <h3 className="font-u1 mb-u1">{title7}</h3>
            <div
              className="markdown-holder-dark mb-u6 font-b"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(content7) }}
            />
          </Box>
        </Inner>
      </DarkGreyNoYBox>
    </>
  );
};

const AboutPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <Layout>
      <BasicPageTemplate frontmatter={frontmatter} />
    </Layout>
  );
};

export default AboutPage;

export const basicPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        tagline
        content
        title2
        content2
        title3
        content3
        title4
        content4
        title5
        link5
        title6
        link6
        title7
        content7
      }
    }
  }
`;
